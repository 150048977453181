@mixin flex-row {
  display: flex;
  align-items: center;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

@mixin fixed-center{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

@mixin fit {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  @mixin scroll-bar-default {
    &::-webkit-scrollbar {
      width: .4em;
      overflow: visible;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
        overflow: visible;
        border-radius: 4px;
        background: $color-cursor;
    }
  }

  @mixin scroll-bar {
    position: absolute;
    background: $color-dark;
    height: 100%;
    top: 0;
    right: 0;
    width: .4em;
    transition: all .5s;
    opacity: 1;
  }

  @mixin scroll-bar-white {
    &::-webkit-scrollbar-thumb {
      background-color: rgba($color: $color-light, $alpha: 0.2);
      visibility: hidden;
    }
  }

  @mixin scroll-bar-display {
    opacity: 0;
    transition: all .5s;
  }
  
  @mixin video-responsive{
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
  }
  @mixin sr {
    position: absolute;
    padding: 0;
    overflow: hidden;
    height: 1px;
    width: 1px;
    clip-path: inset(0 0 99.9% 99.9%);
  }
  
  @function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
      @return $number / ($number * 0 + 1);
    }
  
    @return $number;
  }
  
  @mixin retina {
    @media only screen and (-webkit-min-device-pixel-ratio: 1.3),
      only screen and (-o-min-device-pixel-ratio: 13/10),
      only screen and (min-resolution: 120dpi) {
      @content;
    }
  }
  
  // html.js / html.no-js will not compile?!
  // hence these workaround selectors
  @mixin js-only {
    html:not(:global(.no-js)) & {
      @content;
    }
  }
  
  @mixin no-js {
    html:not(:global(.js)) & {
      @content;
    }
  }
  
  // gsap trans-in defaults
  @mixin will-animate($transform: true, $opacity: true) {
  
    @include js-only {
      @if $opacity == true {
        opacity: 0;
      }
  
      @if $transform == true {
        will-change: opacity, transform;
      }
  
      @else {
        will-change: opacity;
      }
    }
  }
  