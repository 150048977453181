@import "fonts";

$gutter: 30px;
$container-gutter-mobile: $gutter;
$container-gutter-tablet: 50px;
$container-gutter-desktop: 70px;

// Fonts
$regular: "Begum Regular", sans-serif;
$medium: "Begum Medium", sans-serif;
$font-size: 1rem;
$font-size-max: 1.1rem;
$font-size-min: 0.9rem;
$font-size-nano: 0.8rem;
$line-height: 1.5;
$font-regular: 500;
$font-bold: 600;

// Colors
$color-white: #ffff;
$color-light: #f5f5f5;
$color-middle-light: #a0a0a0;
$color-dark: #131212;
$color-gray: #404040;
$color-middle-gray: #a0a0a0;
$color-cursor: #f03232;

// Custom break point (Min)
$min-1800: 1800px;
$min-1440: 1440px;
$min-1280: 1280px;
$min-1200: 1200px;
$min-1080: 1080px;
$min-1024: 1024px;
$min-960: 960px;
$min-840: 840px;
$min-768: 768px;
$min-720: 720px;
$min-640: 640px;
$min-600: 600px;
$min-560: 560px;
$min-540: 540px;
$min-480: 480px;
$min-420: 420px;
$min-375: 375px;
$min-360: 360px;

// Custom break points (Max)
$max-1280: 1279px;
$max-1200: 1199px;
$max-1080: 1079px;
$max-1024: 1023px;
$max-960: 959px;
$max-840: 839px;
$max-768: 767px;
$max-720: 719px;
$max-640: 639px;
$max-600: 599px;
$max-540: 539px;
$max-480: 479px;
$max-420: 419px;
$max-375: 374px;
$max-360: 359px;

// Predefined breakpoints

$min-mobile: "screen and (max-width: " + $min-420 + ")";
$min-tablet: "screen and (max-width: " + $min-720 + ")";
$min-desktop:"screen and (max-width: " + $min-1080+ ")";