@-ms-viewport {
    width: device-width;
  }
  
  @-o-viewport {
    width: device-width;
  }
  
  @viewport {
    width: device-width;
  }
  
  html,
  body {
    background: $color-dark;
    color: $color-light;
    min-height: 100vh;
    @media screen and (min-width: $min-840) {
      height: 100%;
      max-height: 100vh;
      overflow: hidden;
      cursor: none;
    }
    @media screen and (max-width: $max-840) {
      cursor: auto;
    }
  }
  
  html {
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  
  body {
    position: relative;
    margin: 0;
    font-family: $regular;
    counter-reset: heading-counter;
    text-size-adjust: 100%;
    overflow-x: hidden;
  }
  
  img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  a{
    text-decoration: none;
    color: $color-dark;
  }

  button{
    background: transparent;
    outline: none;
    border: none;
    font-size: $font-size;
    text-transform: uppercase;
    font-family: $regular;
}

ul{
  @include reset-ul;
}

h1, h2, h3, h4, h5, h6{
  font-family: $medium;
}